import React, { useState, useEffect } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";

// component
import ToggleButton from "../ToggleButton/ToggleButton";
import ProgramIncludes from "../../../BookPack/ProgramIncludes";

// css
import * as styles from "./PrecisionTreatment.module.css";

const PrecisionTreatment = props => {
  const [currentToggleState, setCurrentToggleState] = useState(
    props.toggleButtonState
  );

  useEffect(() => {
    setCurrentToggleState(props.toggleButtonState);
  }, [props.toggleButtonState]);

  const urlWithQueryParam = `${props.doctorLandingPageUrl}#doctorLp__contactUs`;

  return (
    <div className="bgLightBlue">
      <div className={styles.docLpPreciseTreatment__hdng}>
        <p>Select Precision Treatment</p>
      </div>
      <div>
        <ToggleButton
          handleToggleButton={props.handleToggleButton}
          doctorSpecializationArr={props.doctorSpecializationArr}
        />
      </div>
      {/* ======= View for Pre-Diabetes ======== */}

      {currentToggleState === "pilot_pre_diabetes" ? (
        <div>
          <div className={`container ${styles.docLpPreciseTreatment__cont}`}>
            <div className="row">
              <div
                className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.docLpPreciseTreatment__cont__imgMob}`}
              >
                <div>
                  <StaticImage
                    src="../../../../images/docLandingPage/diabetes.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__contSec}>
                  <h2>
                    Beating Diabetes is possible with your Whole Body Digital
                    Twin™
                  </h2>
                  <p>
                    The Whole Body Digital Twin™ is a digital representation of
                    your unique metabolism and delivers precise, personalized
                    guidance about foods, sleep, activity, and breathing through
                    the easy-to-use app.
                  </p>
                  <p>
                    Twin Health’s program combines the Whole Body Digital Twin™
                    with a dedicated care team that monitors your sensor data,
                    offers personalized recommendations, and supports you on
                    your health journey.
                  </p>
                  <div className={styles.docLpPreciseTreatment__contSec__aTag}>
                    <AnchorLink
                      to={urlWithQueryParam}
                      className="1-1-consultation-link"
                    >
                      1 on 1 consultation
                    </AnchorLink>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__cont__img}>
                  <StaticImage
                    src="../../../../images/docLandingPage/diabetes_banner.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            {/* Program Includes Section */}
            <div>
              <ProgramIncludes />
            </div>
          </div>
          <div
            className={`container bgWhiteColor ${styles.docLpPreciseTreatment__detailCont}`}
          >
            {/* Content Section */}
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__cont__descImg}>
                  <StaticImage
                    src="../../../../images/docLandingPage/diabetes_1.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div
                  className={styles.docLpPreciseTreatment__cont__descDetails}
                >
                  <h3>What Is Pre-Diabetes?</h3>
                  <p className="mt-3">
                    Diabetes is a disease that occurs when your blood glucose,
                    also called blood sugar, is too high. Glucose is your body’s
                    main source of energy. Your body can make glucose, but
                    glucose also comes from the food you eat.
                  </p>
                  <p className="mt-3">
                    Insulin is a hormone made by the pancreas that helps glucose
                    get into your cells to be used for energy. If you have
                    diabetes, your body doesn’t make enough—or any—insulin, or
                    doesn’t use insulin properly. Glucose then stays in your
                    blood and doesn’t reach your cells.
                  </p>
                  <p className="mt-3">
                    Diabetes raises the risk for damage to the eyes, kidneys,
                    nerves, and heart. Diabetes is also linked to some types of
                    cancer. Taking steps to prevent or manage diabetes may lower
                    your risk of developing diabetes health problems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* ====== View for Diabetes ====== */}

      {currentToggleState === "pilot_diabetes" ? (
        <div>
          <div className={`container ${styles.docLpPreciseTreatment__cont}`}>
            <div className="row">
              <div
                className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.docLpPreciseTreatment__cont__imgMob}`}
              >
                <div>
                  <StaticImage
                    src="../../../../images/docLandingPage/diabetes.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__contSec}>
                  <h2>
                    Beating Diabetes is possible with your Whole Body Digital
                    Twin™
                  </h2>
                  <p>
                    The Whole Body Digital Twin™ is a digital representation of
                    your unique metabolism and delivers precise, personalized
                    guidance about foods, sleep, activity, and breathing through
                    the easy-to-use app.
                  </p>
                  <p>
                    Twin Health’s program combines the Whole Body Digital Twin™
                    with a dedicated care team that monitors your sensor data,
                    offers personalized recommendations, and supports you on
                    your health journey.
                  </p>
                  <div className={styles.docLpPreciseTreatment__contSec__aTag}>
                    <AnchorLink
                      to={urlWithQueryParam}
                      className="1-1-consultation-link"
                    >
                      1 on 1 consultation
                    </AnchorLink>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__cont__img}>
                  <StaticImage
                    src="../../../../images/docLandingPage/diabetes_banner.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            {/* Program Includes Section */}
            <div>
              <ProgramIncludes />
            </div>
          </div>
          <div
            className={`container bgWhiteColor ${styles.docLpPreciseTreatment__detailCont}`}
          >
            {/* Content Section */}
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__cont__descImg}>
                  <StaticImage
                    src="../../../../images/docLandingPage/diabetes_1.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div
                  className={styles.docLpPreciseTreatment__cont__descDetails}
                >
                  <h3>What Is Diabetes?</h3>
                  <p className="mt-3">
                    Diabetes is a disease that occurs when your blood glucose,
                    also called blood sugar, is too high. Glucose is your body’s
                    main source of energy. Your body can make glucose, but
                    glucose also comes from the food you eat.
                  </p>
                  <p className="mt-3">
                    Insulin is a hormone made by the pancreas that helps glucose
                    get into your cells to be used for energy. If you have
                    diabetes, your body doesn’t make enough—or any—insulin, or
                    doesn’t use insulin properly. Glucose then stays in your
                    blood and doesn’t reach your cells.
                  </p>
                  <p className="mt-3">
                    Diabetes raises the risk for damage to the eyes, kidneys,
                    nerves, and heart. Diabetes is also linked to some types of
                    cancer. Taking steps to prevent or manage diabetes may lower
                    your risk of developing diabetes health problems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* ====== View for Obesity ====== */}

      {currentToggleState === "pilot_obesity" ? (
        <div>
          <div className={`container ${styles.docLpPreciseTreatment__cont}`}>
            <div className="row">
              <div
                className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.docLpPreciseTreatment__cont__imgMob}`}
              >
                <div>
                  <StaticImage
                    src="../../../../images/docLandingPage/obesity.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__contSec}>
                  <h2>
                    Beating Obesity is possible with your Whole Body Digital
                    Twin™
                  </h2>
                  <p>
                    The Whole Body Digital Twin™ is a digital representation of
                    your unique metabolism and delivers precise, personalized
                    guidance about foods, sleep, activity, and breathing through
                    the easy-to-use app.
                  </p>
                  <p>
                    Twin Health’s program combines the Whole Body Digital Twin™
                    with a dedicated care team that monitors your sensor data,
                    offers personalized recommendations, and supports you on
                    your health journey.
                  </p>
                  <div className={styles.docLpPreciseTreatment__contSec__aTag}>
                    <AnchorLink
                      to={urlWithQueryParam}
                      className="1-1-consultation-link"
                    >
                      1 on 1 consultation
                    </AnchorLink>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__cont__img}>
                  <StaticImage
                    src="../../../../images/docLandingPage/obesity_banner_2.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            {/* Program Includes Section */}
            <div>
              <ProgramIncludes />
            </div>
          </div>
          <div
            className={`container bgWhiteColor ${styles.docLpPreciseTreatment__detailCont}`}
          >
            {/* Content Section */}
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div
                  className={styles.docLpPreciseTreatment__cont__descImgObesity}
                >
                  <StaticImage
                    src="../../../../images/docLandingPage/obesity_1.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div
                  className={styles.docLpPreciseTreatment__cont__descDetails}
                >
                  <h3>What Is Obesity?</h3>
                  <p className="mt-3">
                    Overweight and obesity are defined as abnormal or excessive
                    fat accumulation that presents a risk to health. A body mass
                    index (BMI) over 25 is considered overweight, and over 30 is
                    obese. The issue has grown to epidemic proportions, with
                    over 4 million people dying each year as a result of being
                    overweight or obese in 2017 according to the global burden
                    of disease.
                  </p>
                  <p className="mt-3">
                    Rates of overweight and obesity continue to grow in adults
                    and children. From 1975 to 2016, the prevalence of
                    overweight or obese children and adolescents aged 5–19 years
                    increased more than four-fold from 4% to 18% globally.
                  </p>
                  <p className="mt-3">
                    Obesity is one side of the double burden of malnutrition,
                    and today more people are obese than underweight in every
                    region except sub-Saharan Africa and Asia. Once considered a
                    problem only in high-income countries, overweight and
                    obesity are now dramatically on the rise in low- and
                    middle-income countries, particularly in urban settings. The
                    vast majority of overweight or obese children live in
                    developing countries, where the rate of increase has been
                    more than 30% higher than that of developed countries.
                  </p>
                </div>
              </div>
              <div
                className={`col-12 ${styles.docLpPreciseTreatment__cont__descImgObesity2}`}
              >
                <StaticImage
                  src="../../../../images/docLandingPage/obesity_2.png"
                  alt="Banner"
                  placeholder="blurred"
                  quality={90}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* ====== View for Metabolic Wellness ====== */}

      {currentToggleState === "pilot_metabolic_wellness" ? (
        <div>
          <div className={`container ${styles.docLpPreciseTreatment__cont}`}>
            <div className="row">
              <div
                className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.docLpPreciseTreatment__cont__imgMob}`}
              >
                <div>
                  <StaticImage
                    src="../../../../images/docLandingPage/metabolic_wellness.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__contSec}>
                  <h2>
                    Metabolic wellness is possible with your Whole Body Digital
                    Twin™
                  </h2>
                  <p>
                    The Whole Body Digital Twin™ is a digital representation of
                    your unique metabolism and delivers precise, personalized
                    guidance about foods, sleep, activity, and breathing through
                    the easy-to-use app.
                  </p>
                  <p>
                    Twin Health’s program combines the Whole Body Digital Twin™
                    with a dedicated care team that monitors your sensor data,
                    offers personalized recommendations, and supports you on
                    your health journey.
                  </p>
                  <div className={styles.docLpPreciseTreatment__contSec__aTag}>
                    <AnchorLink
                      to={urlWithQueryParam}
                      className="1-1-consultation-link"
                    >
                      1 on 1 consultation
                    </AnchorLink>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__cont__img}>
                  <StaticImage
                    src="../../../../images/docLandingPage/metabolic_1.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            {/* Program Includes Section */}
            <div>
              <ProgramIncludes />
            </div>
          </div>
          <div
            className={`container bgWhiteColor ${styles.docLpPreciseTreatment__detailCont}`}
          >
            {/* Content Section */}
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__cont__descImg}>
                  <StaticImage
                    src="../../../../images/docLandingPage/metabolic_wellness_1.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div
                  className={styles.docLpPreciseTreatment__cont__descDetails}
                >
                  <h3>What Is Metabolic Wellness?</h3>
                  <p className="mt-3">
                    Metabolic wellness means that your body can digest and
                    absorb nutrients from the food that you eat without
                    unhealthy spikes in blood sugar, blood fat, inflammation,
                    and insulin.
                  </p>
                  <p className="mt-3">
                    It’s important to avoid these big spikes because they can
                    contribute to the unfavorable long-term impact of food on
                    your health, such as high cholesterol, high levels of body
                    fat, a large waist circumference, and high blood pressure.
                  </p>
                  <p className="mt-3">
                    In other words, to be metabolically healthy means that your
                    body is able to respond to food in a beneficial way that
                    reduces your risk of conditions such as obesity, type 2
                    diabetes, heart disease, stroke, kidney disease, and
                    nonalcoholic fatty liver disease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* ====== View for Hypertension ====== */}

      {currentToggleState === "pilot_hypertension" ? (
        <div>
          <div className={`container ${styles.docLpPreciseTreatment__cont}`}>
            <div className="row">
              <div
                className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.docLpPreciseTreatment__cont__imgMob}`}
              >
                <div>
                  <StaticImage
                    src="../../../../images/docLandingPage/hypertension.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__contSec}>
                  <h2>
                    Beating Hypertension is possible with your Whole Body
                    Digital Twin™
                  </h2>
                  <p>
                    The Whole Body Digital Twin™ is a digital representation of
                    your unique metabolism and delivers precise, personalized
                    guidance about foods, sleep, activity, and breathing through
                    the easy-to-use app.
                  </p>
                  <p>
                    Twin Health’s program combines the Whole Body Digital Twin™
                    with a dedicated care team that monitors your sensor data,
                    offers personalized recommendations, and supports you on
                    your health journey.
                  </p>
                  <div className={styles.docLpPreciseTreatment__contSec__aTag}>
                    <AnchorLink
                      to={urlWithQueryParam}
                      className="1-1-consultation-link"
                    >
                      1 on 1 consultation
                    </AnchorLink>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__cont__img}>
                  <StaticImage
                    src="../../../../images/docLandingPage/hyper_1.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            {/* Program Includes Section */}
            <div>
              <ProgramIncludes />
            </div>
          </div>
          <div
            className={`container bgWhiteColor ${styles.docLpPreciseTreatment__detailCont}`}
          >
            {/* Content Section */}
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__cont__descImg}>
                  <StaticImage
                    src="../../../../images/docLandingPage/diabetes_1.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div
                  className={styles.docLpPreciseTreatment__cont__descDetails}
                >
                  <h3>What Is Hypertension?</h3>
                  <p className="mt-3">
                    Hypertension (high blood pressure) is when the pressure in
                    your blood vessels is too high (140/90 mmHg or higher). It
                    is common but can be serious if not treated.
                  </p>
                  <p className="mt-3">
                    People with high blood pressure may not feel symptoms. The
                    only way to know is to get your blood pressure checked.
                    Lifestyle changes like eating a healthier diet, quitting
                    tobacco and being more active can help lower blood pressure.
                    Some people may still need to take medicines.
                  </p>
                  <p className="mt-3">
                    Blood pressure is written as two numbers. The first
                    (systolic) number represents the pressure in blood vessels
                    when the heart contracts or beats. The second (diastolic)
                    number represents the pressure in the vessels when the heart
                    rests between beats.Hypertension is diagnosed if, when it is
                    measured on two different days, the systolic blood pressure
                    readings on both days is ≥140 mmHg and/or the diastolic
                    blood pressure readings on both days is ≥90 mmHg.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* ====== View for PCOD ====== */}

      {currentToggleState === "pilot_pcod" ? (
        <div>
          <div className={`container ${styles.docLpPreciseTreatment__cont}`}>
            <div className="row">
              <div
                className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.docLpPreciseTreatment__cont__imgMob}`}
              >
                <div>
                  <StaticImage
                    src="../../../../images/docLandingPage/diabetes.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__contSec}>
                  <h2>
                    Beating PCOD is possible with your Whole Body Digital Twin™
                  </h2>
                  <p>
                    The Whole Body Digital Twin™ is a digital representation of
                    your unique metabolism and delivers precise, personalized
                    guidance about foods, sleep, activity, and breathing through
                    the easy-to-use app.
                  </p>
                  <p>
                    Twin Health’s program combines the Whole Body Digital Twin™
                    with a dedicated care team that monitors your sensor data,
                    offers personalized recommendations, and supports you on
                    your health journey.
                  </p>
                  <div className={styles.docLpPreciseTreatment__contSec__aTag}>
                    <AnchorLink
                      to={urlWithQueryParam}
                      className="1-1-consultation-link"
                    >
                      1 on 1 consultation
                    </AnchorLink>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__cont__img}>
                  <StaticImage
                    src="../../../../images/docLandingPage/diabetes_banner.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            {/* Program Includes Section */}
            <div>
              <ProgramIncludes />
            </div>
          </div>
          <div
            className={`container bgWhiteColor ${styles.docLpPreciseTreatment__detailCont}`}
          >
            {/* Content Section */}
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className={styles.docLpPreciseTreatment__cont__descImg}>
                  <StaticImage
                    src="../../../../images/docLandingPage/pcod_1.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div
                  className={styles.docLpPreciseTreatment__cont__descDetails}
                >
                  <h3>What Is PCOD?</h3>
                  <p className="mt-3">
                    PCOD (Polycystic Ovarian Disease) is mostly caused by a
                    combination of hormonal imbalance and genetic tendencies. In
                    a standard menstrual cycle, the two ovaries will alternately
                    release mature, ready-to-be-fertilized eggs each month. For
                    someone with PCOD, however, the ovaries will often release
                    either immature or only partially-mature eggs, which can go
                    on to develop into cysts(little sacs filled with liquid).
                  </p>
                  <p className="mt-3">
                    This also leads to the ovaries swelling and becoming
                    enlarged. Generally, the ovaries release a limited amount of
                    androgens (male hormones) during the cycle - but in this
                    case, the ovaries will start producing androgens in excess,
                    which leads to symptoms like male pattern hair loss,
                    abdominal weight gain, irregular periods, and in some
                    extreme cases, even infertility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PrecisionTreatment;
