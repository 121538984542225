// extracted by mini-css-extract-plugin
export var newContentVideoModal = "PersonalizedSolution-module--newContentVideoModal--sp5IM";
export var newVideoModalClose = "PersonalizedSolution-module--newVideoModalClose--AKzd5";
export var personalSol__hdng = "PersonalizedSolution-module--personalSol__hdng--cM21K";
export var personalSol__img = "PersonalizedSolution-module--personalSol__img--5t3bu";
export var personalSol__imgSec = "PersonalizedSolution-module--personalSol__imgSec--RqmXQ";
export var personalSol__row = "PersonalizedSolution-module--personalSol__row--aWO54";
export var personalSol__subHdng = "PersonalizedSolution-module--personalSol__subHdng--7XZue";
export var twinReverseSec__bottomTxt = "PersonalizedSolution-module--twinReverseSec__bottomTxt--iyGas";
export var twinReverseSec__cardSec = "PersonalizedSolution-module--twinReverseSec__cardSec--Ro9uV";
export var twinReverseSec__col = "PersonalizedSolution-module--twinReverseSec__col--5Uzyv";
export var twinReverseSec__cont = "PersonalizedSolution-module--twinReverseSec__cont--EZdso";
export var twinReverseSec__hdng = "PersonalizedSolution-module--twinReverseSec__hdng--roNSC";
export var twinReverseSec__row = "PersonalizedSolution-module--twinReverseSec__row--jPbdo";