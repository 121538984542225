import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// css
import * as styles from "./NeedToSuccessful.module.css";

const NeedToSuccessful = () => {
  return (
    <div className="bgLightBlue">
      <div className={`container ${styles.successfulSection__contner}`}>
        <div className={styles.successfulSection__hdng}>
          <h3>
            Everything <span>you</span> need to be successful
          </h3>
        </div>
        <div className={styles.successfulSection__logoSec}>
          <StaticImage
            src="../../../../images/twin-logo/favicon_2024.png"
            alt=""
            className="img-fluid"
            placeholder="blurred"
            quality={90}
          />
          <p>
            Your own <span>Whole Body Digital Twin</span> – a <br></br> dynamic
            digital representation of your<br></br> unique metabolism
          </p>
        </div>
        <div className="row">
          <div
            className={`col-12 col-md-4 col-lg-4 ${styles.successfulSection__col1}`}
          >
            <div className={styles.successfulSection__col1__img}>
              <StaticImage
                src="../../../../images/LandingPage/icon_5.png"
                alt=""
                className="img-fluid"
                placeholder="blurred"
                quality={90}
              />
            </div>
            <p>
              Easy to use <span>devices</span> (included!) that track your
              health daily
            </p>
          </div>
          <div
            className={`col-12 col-md-4 col-lg-4 ${styles.successfulSection__col2}`}
          >
            <StaticImage
              src="../../../../images/LandingPage/icon_7.png"
              alt=""
              className="img-fluid"
              placeholder="blurred"
              quality={90}
            />
          </div>
          <div
            className={`col-12 col-md-4 col-lg-4  ${styles.successfulSection__col3}`}
          >
            <div className={styles.successfulSection__col3__img}>
              <StaticImage
                src="../../../../images/LandingPage/icon_6.png"
                alt=""
                className="img-fluid"
                placeholder="blurred"
                quality={90}
              />
            </div>
            <p>
              A dedicated and supportive <span>care team</span> (provider,
              nurse, and health coach)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeedToSuccessful;
