import React from "react";

// css
import * as styles from "./FAQ.module.css";

const FaqSection = () => {
  return (
    <div className="bgLightWhite">
      <div className={`container ${styles.faqSection__contner}`}>
        <div className={styles.faqSection__hdng}>
          <h3>Frequently asked questions</h3>
        </div>
        <div className={`row ${styles.faqSection__row1}`}>
          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <div className={styles.faqSection__leftCard}>
              <h3>
                How is <span>Twin different</span> from other programs?
              </h3>
              <p>
                Twin uses real time data monitoring to understand how your body
                responds to thousands of variables. We then personalize your
                treatment plan, connecting you and our care team via our
                (human-centered) technology platform, the Whole Body Digital
                Twin.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <div className={styles.faqSection__rightCard}>
              <h3>
                What is my <span>Whole Body Digital Twin?</span>
              </h3>
              <p>
                Your Whole Body Digital Twin is a digital representation of your
                current metabolic health, which you are able to view in your
                Twin mobile app once you become a member. Your Twin learns from
                the data you provide it with and then makes personalized
                recommendations to heal you, and only you.
              </p>
            </div>
          </div>
        </div>
        <div className={`row ${styles.faqSection__row2}`}>
          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <div className={styles.faqSection__leftCard}>
              <h3>
                Will I have to <span>eat less and exercise a ton?</span>
              </h3>
              <p>
                We will not limit the amount you eat, or recommend you start
                running in triathlons. We meet people where they are currently
                at. This is not an elimination diet, on the contrary, we
                frequently have members who can reintroduce old favorites back
                into their diet, like pasta and bread.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3">
            <div className={styles.faqSection__rightCard}>
              <h3>
                Is type 2 <span>diabetes reversal</span> really possible?
              </h3>
              <p>
                100%. With Twin, you can achieve diabetes remission. This is
                possible because instead of treating the symptom of high blood
                sugar, Twin addresses the root cause of type 2 diabetes, which
                is a disrupted metabolism. Twin works to heal the metabolism
                from the inside out, to achieve diabetes remission of type 2
                diabetes.
              </p>
            </div>
          </div>
        </div>
        <div className={`row ${styles.faqSection__row3}`}>
          <div className="col-12 mt-3">
            <div className={styles.faqSection__lastCard}>
              <h3>
                Will you keep my <span>health information private?</span>
              </h3>
              <p>
                Absolutely. Your company will not have any access to your
                medical records or medical history. We keep all of your health
                information private, between you and your Twin care team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
