import React from "react";

// styles.
import * as styles from "./Banner.module.css";

const Banner = props => {
  return (
    <div className={`container bgDarkBlue ${styles.docLpBanner__cont}`}>
      <div className="row">
        {/* Doctor Image */}
        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <div className={styles.docLpBanner__img}>
            <img
              src={props.profilePhoto}
              alt="doctor_photo"
              placeholder="blurred"
              loading="lazy"
            />
          </div>
        </div>
        {/* Doctor Details */}
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div className={styles.docLpBanner__docDetails}>
            <h3 className="mb-2">{props.name}</h3>
            <p className="mb-3">{props.designation}</p>
            <div className={styles.docLpBanner__line}></div>
            <h3 className="mt-3">{props.doctorHospitalName}</h3>
            <p className="mt-3">
              {props.address}, {props.city}, {props.state}-{props.pincode}
            </p>
            {props.doctorAssistantName ? (
              <div className={styles.docLpBanner__assisDtls}>
                <p>To directly enroll contact clinic manager/educator,</p>
                <p>
                  {props.doctorAssistantName} - {props.doctorAssistantNumber}
                </p>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-1"></div>
      </div>
    </div>
  );
};

export default Banner;
