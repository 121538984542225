import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import * as styles from "./WholeDigitalTwin.module.css";

const WholeDigitalTwin = (props) => {
  const urlWithQueryParam = `${props.doctorLandingPageUrl}#doctorLp__contactUs`;

  return (
    <div className="bgDarkBlue">
      <div className={`container ${styles.preciseHealingSec}`}>
        <div className={`row`}>
          <div
            className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.preciseHealingSec__cntntCol}`}
          >
            <div className={styles.preciseHealingSec__cntnt}>
              <h3>About Whole body digital twin</h3>
              <p>
                The Whole Body Digital Twin™ is a digital representation of your
                unique metabolism and delivers precise, personalized guidance
                about foods, sleep, activity, and breathing through the
                easy-to-use app.
              </p>
              <p>
                Twin Health’s program combines the Whole Body Digital Twin™ with
                a dedicated care team that monitors your sensor data, offers
                personalized recommendations, and supports you on your health
                journey.
              </p>
              <div className={styles.docLpPreciseTreatment__contSec__aTag}>
                <AnchorLink to={urlWithQueryParam} className="1-1-consultation-link">
                  1 on 1 consultation
                </AnchorLink>
              </div>
            </div>
          </div>
          <div
            className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 ${styles.preciseHealingSec__bannerImgCol}`}
          >
            <div>
              <StaticImage
                src="../../../../images/home/precise_img.png"
                alt="video"
                placeholder="blurred"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WholeDigitalTwin;
