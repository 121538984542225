import React, { useState, useRef } from "react";
// import Loader from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { StaticImage } from "gatsby-plugin-image";
import { debounce } from "lodash";
import { navigate } from "gatsby";

// styles
import * as styles from "./ContactUs.module.css";

// constants
import constants from "../../../../constants";

// components
import DropDown from "../../../DropDown/DropDown";

// services
import { sendToLSQ } from "../../../../services/LeadSquared";

// utils
import { splitName } from "../../../../utils";

const ContactUs = props => {
  const [userInfo, setUserInfo] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState([]); // selected language values
  const [showLanguageError, setShowLanguageError] = useState("none"); // show error for language selection if left empty

  const [selectedTime, setSelectedTime] = useState([]);
  const [showTimeError, setShowTimeError] = useState("none");

  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); // change the ui after form submission

  const languageRef = useRef();
  const timeRef = useRef();

  const urlForBookPack = `/doctor/wbdt-care-doctorlp-plan/?clinicId=${props.indiaClinicId}&doctorName=${props.name}&doctorEmail=${props.email}&doctorPhone=${props.doctorPhoneNumber}&specialization=${props.doctorSpecialization}&name=${userInfo.name}&phone=${userInfo.phone}&email=${userInfo.email}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });

  const onSubmit = async data => {
    const languageArr = selectedLanguage.map(item => item.name);
    const timeArr = selectedTime.map(item => item.name);

    data.language = languageArr.join();
    data.time = timeArr.join();

    // set user details
    setUserInfo({ name: data.name, phone: data.phone, email: data.email });

    // util function -> to split the name
    const { firstName, lastName } = splitName(data.name);

    // Saving User Data To LSQ
    const leadSquaredParams = {
      FirstName: firstName,
      LastName: lastName,
      Phone: data.phone,
      EmailAddress: data.email,
      mx_City: data.city,
      mx_Language: data.language,
      mx_Preferred_time_of_call: data.time,
      mx_Website_CTA: "Doctor_Landing_Page_Referral",
      mx_Latest_Source: "New Website",
      mx_Doc_Channel_Doctor: props.name,
      mx_Referral_Doctor_IDs: props.indiaClinicId,
      Source: "Doctor Landing Page",
    };

    try {
      setLoading(true);
      setFormSubmitted(true);
      // LSQ Call
      await sendToLSQ(leadSquaredParams);
      setLoading(false);
      reset();
      languageRef.current.resetSelectedValues();
      setSelectedLanguage([]);
      setShowLanguageError("none");
      timeRef.current.resetSelectedValues();
      setSelectedTime([]);
      setShowTimeError("none");
    } catch (error) {
      console.error("lsq error", error);
      setLoading(false);
    }
  };

  // Handle language selection
  const handleLanguageSelection = value => {
    if (value.length > 0) {
      setShowLanguageError("none");
      setSelectedLanguage(value);
    } else {
      setShowLanguageError("block");
    }

    // auto closing the popup
    languageRef.current &&
      value.length > 1 &&
      debounce(() => {
        languageRef.current.searchBox.current.blur();
      }, 1000)();
  };

  const handleTimeSelection = value => {
    if (value.length > 0) {
      setShowTimeError("none");
      setSelectedTime(value);
    } else {
      setShowTimeError("block");
    }
  };

  return (
    <div className="bgDarkBlue">
      <div className={`container ${styles.docLpContact__cont}`}>
        {!formSubmitted ? (
          <div className="row">
            <div className="col-12 col-md-4 col-lg-4">
              <div className={styles.docLpContact__cntntSec}>
                <p>
                  One on one consultation to directly enroll contact clinic
                  manager/educator
                </p>
                <div className={styles.docLpContact__line}></div>
                <h3>{props.doctorAssistantName}</h3>
                <h3>{props.doctorAssistantNumber}</h3>
              </div>
            </div>
            <div className="col-1">
              <div className={styles.docLpContact__line2}></div>
            </div>
            <div className="col-12 col-md-7 col-lg-7">
              <div>
                <div className={styles.docLpContact__formSec}>
                  <h3>One on one consultation</h3>
                  <div className={styles.docLpContact__formSec__form}>
                    <form
                      name="contactUs"
                      onSubmit={handleSubmit(onSubmit)}
                      className={`mt-3`}
                    >
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div
                            className={`form-group mb-4 mb-md-5 ${styles.formInput}`}
                          >
                            <input
                              className={
                                errors.name
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="Full Name*"
                              id="name"
                              type="text"
                              {...register("name", {
                                required: "Please enter your name",
                                pattern: {
                                  value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                                  message: "Please enter your valid name",
                                },
                                maxLength: {
                                  value: 50,
                                  message: "Name is too long",
                                },
                              })}
                            />
                            {errors.name && (
                              <span className="invalid-feedback">
                                {errors.name.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div
                            className={`form-group mb-4 mb-md-5 ${styles.formInput}`}
                          >
                            <input
                              className={`form-control letterSp1 ${
                                errors.phone ? "is-invalid" : ""
                              }`}
                              placeholder="Mobile Number*"
                              id="phone"
                              type="text"
                              inputMode="numeric"
                              maxLength="10"
                              {...register("phone", {
                                required: "Please enter your mobile number",
                                pattern: {
                                  value:
                                    /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                                  message:
                                    "Please enter your valid phone number",
                                },
                                maxLength: {
                                  value: 10,
                                  message: "Invalid phone number",
                                },
                              })}
                            />
                            {errors.phone && (
                              <span className="invalid-feedback">
                                {errors.phone.message}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div
                            className={`form-group mb-4 mb-md-5 ${styles.formInput}`}
                          >
                            <input
                              className={
                                errors.email
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="Email*"
                              id="email"
                              type="email"
                              {...register("email", {
                                required: "Please enter your email",
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                                  message: "Please enter your valid email",
                                },
                              })}
                            />
                            {errors.email && (
                              <span className="invalid-feedback">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div
                            className={`form-group mb-4 mb-md-5 ${styles.formInput}`}
                          >
                            <input
                              className={
                                errors.city
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="City*"
                              id="city"
                              type="text"
                              {...register("city", {
                                required: "Please enter your city",
                                pattern: {
                                  value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                                  message: "Please enter your valid city",
                                },
                                maxLength: {
                                  value: 50,
                                  message: "City is too long",
                                },
                              })}
                            />
                            {errors.city && (
                              <span className="invalid-feedback">
                                {errors.city.message}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className={`col-12 col-md-6`}>
                          <div
                            className={`form-group mb-4 mb-md-5 ${styles.bookpackForm__lngFormInput}`}
                          >
                            <DropDown
                              singleSelect={false}
                              displayValue="display"
                              onSelect={handleLanguageSelection}
                              onRemove={handleLanguageSelection}
                              options={constants.GET_IN_TOUCH_LANGUAGES}
                              showCheckbox={true}
                              selectionLimit={2}
                              value={selectedLanguage}
                              placeholder="Language preference (any two languages)*"
                              hidePlaceholder={true}
                              showArrow={true}
                              ref={languageRef}
                              customCloseIcon={
                                <StaticImage
                                  src="../../../../images/bookPack/cross_2.png"
                                  alt="cross_icon"
                                  className="img-fluid"
                                  placeholder="blurred"
                                  quality={90}
                                  style={{
                                    width: "14px",
                                    height: "14px",
                                    marginTop: "2px",
                                    marginLeft: "2px",
                                  }}
                                />
                              }
                              customArrow={
                                <StaticImage
                                  src="../../../../images/bookPack/arrow_dropdown.png"
                                  alt="cross_icon"
                                  className="img-fluid"
                                  placeholder="blurred"
                                  quality={90}
                                  style={{
                                    marginTop: "9px",
                                  }}
                                />
                              }
                            />
                            {/* To show language error */}
                            {selectedLanguage.length === 0 && (
                              <span
                                className="text-danger"
                                style={{
                                  fontSize: "0.875em",
                                  display: showLanguageError,
                                }}
                              >
                                <p style={{ marginTop: "4px" }}>
                                  Please select a language
                                </p>
                              </span>
                            )}
                          </div>
                        </div>

                        <div className={`col-12 col-md-6`}>
                          <div
                            className={`form-group mb-4 mb-md-5 ${styles.bookpackForm__lngFormInput}`}
                          >
                            <DropDown
                              singleSelect={true}
                              displayValue="display"
                              onSelect={handleTimeSelection}
                              onRemove={handleTimeSelection}
                              options={constants.GET_IN_TOUCH_TIMES}
                              showCheckbox={false}
                              value={selectedTime}
                              placeholder="Preferred time to call*"
                              hidePlaceholder={true}
                              showArrow={true}
                              ref={timeRef}
                              customCloseIcon={
                                <StaticImage
                                  src="../../../../images/bookPack/cross_2.png"
                                  alt="cross_icon"
                                  className="img-fluid"
                                  placeholder="blurred"
                                  quality={90}
                                  style={{
                                    width: "14px",
                                    height: "14px",
                                    marginTop: "2px",
                                    marginLeft: "2px",
                                  }}
                                />
                              }
                              customArrow={
                                <StaticImage
                                  src="../../../../images/bookPack/arrow_dropdown.png"
                                  alt="cross_icon"
                                  className="img-fluid"
                                  placeholder="blurred"
                                  quality={90}
                                  style={{
                                    marginTop: "9px",
                                  }}
                                />
                              }
                            />
                            {/* To show language error */}
                            {selectedTime.length === 0 && (
                              <span
                                className="text-danger"
                                style={{
                                  fontSize: "0.875em",
                                  display: showTimeError,
                                }}
                              >
                                <p style={{ marginTop: "4px" }}>
                                  Please select a preferred time
                                </p>
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className={`form-group mb-3`}>
                            <div class="custom-control">
                              <div className="d-flex align-items-center">
                                <input
                                  className={`custom-control-input ${styles.checkbox}`}
                                  type="checkbox"
                                  id="flexCheckChecked"
                                  {...register("terms", {
                                    required:
                                      "Please check contacted by Twin Health via Call, SMS, WhatsApp & Email box",
                                  })}
                                />
                                <label
                                  className={`custom-control-label ${styles.checkLabel}`}
                                  for="flexCheckChecked"
                                  htmlFor="terms-checkbox"
                                >
                                  I agree to be contacted by Twin Health via
                                  Call, SMS, WhatsApp & Email
                                </label>
                              </div>
                              {errors.terms && (
                                <span className={styles.invalid}>
                                  {errors.terms.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mt-4 mt-md-5 text-center">
                          <button
                            type="submit"
                            onClick={() => {
                              setShowLanguageError("block");
                              setShowTimeError("block");
                            }}
                            className={`btn ${styles.submitBtn} ${
                              loading && "btnEvent"
                            }`}
                          >
                            Request for 1-1 consultation
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className={`col-12 text-center ${styles.docLpContact__formSubCol}`}
            >
              <h3>One on one consultation</h3>
              <StaticImage
                src="../../../../images/docLandingPage/tick_new.png"
                alt="cross_icon"
                className="img-fluid"
                placeholder="blurred"
                quality={90}
              />
              <h4>Thank you! for completing consultation form</h4>
              <p>
                A member from the Twin team will reach out to you. You can also
                enroll now in the program.
              </p>
              <button onClick={() => navigate(urlForBookPack)}>
                Enroll Now
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
