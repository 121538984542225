import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

// component
import VideoModal from "./VideoModal";

// css
import * as styles from "./PersonalizedSolution.module.css";

const PersonalizedSolution = props => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const toggleVideo = () => {
    setShowVideoModal(!showVideoModal);
  };

  return (
    <div className="bgLightBlue">
      <div className="container">
        <div className={`row ${styles.personalSol__row}`}>
          {/* Heading */}
          <div className={`text-center ${styles.personalSol__hdng}`}>
            <h3>
              A solution <span>personalized</span>, just for you
            </h3>
          </div>
          {/* Video-Section */}
          {/* <div className="col-md-1 col-lg-1"></div> */}
          <div
            className={`col-12 col-md-12 col-lg-12 ${styles.personalSol__imgSec}`}
            role="presentation"
            onClick={() => {
              toggleVideo();
            }}
          >
            <div className={styles.personalSol__img}>
              <StaticImage
                src="../../../../images/LandingPage/banner_3.png"
                alt=""
                className="img-fluid"
                placeholder="blurred"
                quality={90}
              />
            </div>

            {/* Sub-Heading */}
            <div className={`text-center ${styles.personalSol__subHdng}`}>
              <p>
                Find out how having your own{" "}
                <span>Whole Body Digital Twin™</span>, can help you achieve
                diabetes remission.
              </p>
            </div>
          </div>
          {/* <div className="col-md-1 col-lg-1"></div> */}
        </div>
      </div>
      <VideoModal
        show={showVideoModal}
        onHide={toggleVideo}
        videoLink={props.videoLink}
      />
    </div>
  );
};

export default PersonalizedSolution;
