import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

// component
import VideoModal from "../PersonalizedSolution/VideoModal";

// css
import * as styles from "./WhatTwin.module.css";

const WhatTwin = props => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const toggleVideo = () => {
    setShowVideoModal(!showVideoModal);
  };

  return (
    <div className="bgLightBlue">
      <div className={`container ${styles.whatTwin__cont}`}>
        <div className={styles.whatTwin__hdng}>
          <h3>
            What is <span>Twin?</span>
          </h3>
        </div>
        <div className={styles.whatTwin__imgSec}>
          <div
            className={styles.whatTwin__img}
            role="presentation"
            onClick={() => {
              toggleVideo();
            }}
          >
            <StaticImage
              src="../../../../images/LandingPage/banner_4.png"
              alt=""
              className="img-fluid"
              placeholder="blurred"
              quality={90}
            />
          </div>

          {/* Sub-Heading */}
          <div className={`text-center ${styles.whatTwin__subHdng}`}>
            <p>
              Dr. Lisa Shah explains the medical science and{" "}
              <span>Whole Body Digital Twin™</span> technology for safely
              achieving diabetes remission of type 2 diabetes.
            </p>
          </div>
        </div>
      </div>
      <VideoModal
        show={showVideoModal}
        onHide={toggleVideo}
        videoLink={props.videoLink}
        videoName={"What is Twin"}
      />
    </div>
  );
};

export default WhatTwin;
