// extracted by mini-css-extract-plugin
export var docLpPreciseTreatment__cont = "PrecisionTreatment-module--docLpPreciseTreatment__cont--40V79";
export var docLpPreciseTreatment__contSec = "PrecisionTreatment-module--docLpPreciseTreatment__contSec--j6DR0";
export var docLpPreciseTreatment__contSec__aTag = "PrecisionTreatment-module--docLpPreciseTreatment__contSec__aTag--CX38f";
export var docLpPreciseTreatment__cont__descDetails = "PrecisionTreatment-module--docLpPreciseTreatment__cont__descDetails--+LdbF";
export var docLpPreciseTreatment__cont__descImg = "PrecisionTreatment-module--docLpPreciseTreatment__cont__descImg--KvuiK";
export var docLpPreciseTreatment__cont__descImgObesity = "PrecisionTreatment-module--docLpPreciseTreatment__cont__descImgObesity--bzmw+";
export var docLpPreciseTreatment__cont__descImgObesity2 = "PrecisionTreatment-module--docLpPreciseTreatment__cont__descImgObesity2--fA0K0";
export var docLpPreciseTreatment__cont__img = "PrecisionTreatment-module--docLpPreciseTreatment__cont__img--bHq67";
export var docLpPreciseTreatment__cont__imgMob = "PrecisionTreatment-module--docLpPreciseTreatment__cont__imgMob--jPtj5";
export var docLpPreciseTreatment__detailCont = "PrecisionTreatment-module--docLpPreciseTreatment__detailCont--LN3w0";
export var docLpPreciseTreatment__hdng = "PrecisionTreatment-module--docLpPreciseTreatment__hdng--pmYUD";