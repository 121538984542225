import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// seo
import Seo from "../Seo";

// constant
import constant from "../../constants";

// components
import HeaderDoctorLp from "../Header/HeaderDoctorLp";
import Banner from "./LandingPage/Banner/Banner";
import PrecisionTreatment from "./LandingPage/PrecisionTreatment/PrecisionTreatment";
import WholeDigitalTwin from "./LandingPage/WholeDigitalTwin/WholeDigitalTwin";
import PersonalizedSolution from "./LandingPage/PersonalizedSolution/PersonalizedSolution";
import DiabetesRemission from "./LandingPage/DiabetesRemission/DiabetesRemission";
import SuccessStoriesV2 from "../Home/SuccessStories/SuccessStoriesV2";
import WhatTwin from "./LandingPage/WhatTwin/WhatTwin";
import NeedToSuccessful from "./LandingPage/NeedToSuccessful/NeedToSuccessful";
import FaqSection from "./LandingPage/FAQ/FAQ";
import HealthAndHappinessSec from "./LandingPage/HealthHappiness/HealthHappiness";
import ContactUs from "./LandingPage/ContactUs/ContactUs";
import FooterTerms from "../Footer/FooterTerms";

// images
import favicon from "../../images/twin-logo/favicon_2024.png";

const DoctorLandingPageTemplate = ({ pageContext }) => {
  const [toggleButtonState, setToggleButtonState] =
    useState("");
  const [doctorSpecializationArr, setDoctorSpecialization] = useState([]);

  useEffect(() => {
    const doctorSpecializationString = pageContext.doctorSpecialization;
    const arrVal = doctorSpecializationString.split(",");
    setToggleButtonState(arrVal[0]);
    setDoctorSpecialization(arrVal);
  }, [pageContext.doctorSpecialization]);

  const handleToggleButton = value => {
    setToggleButtonState(value);
  };

  return (
    <div>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>

      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />

      <HeaderDoctorLp {...pageContext} />
      <Banner {...pageContext} />
      <PrecisionTreatment
        handleToggleButton={handleToggleButton}
        toggleButtonState={toggleButtonState}
        doctorSpecializationArr={doctorSpecializationArr}
        {...pageContext}
      />
      <WholeDigitalTwin {...pageContext} />
      <PersonalizedSolution
        videoLink={constant.HOME_PAGE_V2.twinCanHelpVideoLink}
      />
      <DiabetesRemission />
      <SuccessStoriesV2 />
      <WhatTwin videoLink={constant.HOME_PAGE_V2.whatIsTwinVideoLink} />
      <NeedToSuccessful />
      <FaqSection />
      <HealthAndHappinessSec />
      <div id="doctorLp__contactUs"></div>
      <ContactUs {...pageContext} />
      <FooterTerms isHome={false} />
    </div>
  );
};

export default DoctorLandingPageTemplate;
