import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// styles
import * as styles from "./Header.module.css";

const HeaderWithLogo = props => {
  const urlWithQueryParam = `${props.doctorLandingPageUrl}#doctorLp__contactUs`;
  const urlForBookPack = `/doctor/wbdt-care-doctorlp-plan/?clinicId=${props.indiaClinicId}&doctorName=${props.name}&doctorEmail=${props.email}&doctorPhone=${props.doctorPhoneNumber}&specialization=${props.doctorSpecialization}`;

  return (
    <>
      <div
        className={`${styles.headerWrapper} ${
          !props.isHome && "whiteBgWrapper"
        } `}
        id="topHeader"
      >
        <nav
          className={`navbar navbar-expand-lg fixed-top navbar-light ${styles.navBar2}
          `}
        >
          <div className={`container`}>
            <div className={`navbar-brand ${styles.mobHeaderDocLp__logo}`}>
              <Link to={props.logoOnClickRedirect}>
                <StaticImage
                  src="../../images/twin-logo/twinLogo3_2024.png"
                  alt="Banner"
                  placeholder="blurred"
                  quality={90}
                  className={styles.logoImage}
                />
              </Link>
              <div className={styles.mobHeaderDocLp__para}>
                <p>{props.doctorHospitalName}</p>
              </div>
              {/* navigation for mobile */}
              <div className={`${styles.mobHeaderDocLp}`}>
                <ul className={`navbar-nav`}>
                  <li className="nav-item">
                    <AnchorLink to={urlWithQueryParam} className="navLink">
                      <div className={`${styles.gradientBtn2}`}>
                        1 on 1 consultation
                        {/* <span className="icon-arrow-forward"></span> */}
                      </div>
                    </AnchorLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.HeaderDocLp__para}>
              <p>{props.doctorHospitalName}</p>
            </div>
            <div
              className={`collapse navbar-collapse justify-content-end ${styles.headerDocLp__para}`}
            >
              <p>Put your Diabetes in Remission, reclaim your health</p>
            </div>
            {/* navigation for desktop */}
            <div className="collapse navbar-collapse justify-content-end">
              <ul className={`navbar-nav ${styles.navBarList}`}>
                <li className="nav-item">
                  <Link to={urlForBookPack} className="navLink">
                    <div className={`${styles.gradientBtn2}`}>
                      Enroll Now
                      {/* <span className="icon-arrow-forward"></span> */}
                    </div>
                  </Link>
                </li>
                <li className="nav-item">
                  <AnchorLink to={urlWithQueryParam} className="navLink">
                    <div className={`${styles.gradientBtn}`}>
                      1 on 1 consultation
                      {/* <span className="icon-arrow-forward"></span> */}
                    </div>
                  </AnchorLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default HeaderWithLogo;

// NOTE
// props.logoPosition = "center" then logo will be placed to center and if value is null then it will positioned in left
// logoOnClickRedirect = url for redirection. For Ex:- logoOnClickRedirect="/"
